$(document).ready(function() {
    let shoppingCart = $('.shopping-cart');
    let shoppingCartHover = $('.shopping-cart-hover');

    let accountWrap = $('.account-wrap');
    let accountWrapHover = $('.account-hover-wrap');

    let searchTrigger = $(".search-trigger");
    let searchOverlay = $(".search-overlay");
    let searchWrapHeader = $(".header-search-wrap");

    shoppingCart.mouseenter(function(){
        shoppingCartHover.fadeIn(200);
    });
    shoppingCart.mouseleave(function(){
        shoppingCartHover.fadeOut(200);
    });
    accountWrap.mouseenter(function(){
        accountWrapHover.fadeIn(200);
    });
    accountWrap.mouseleave(function(){
        accountWrapHover.fadeOut(200);
    });
    searchTrigger.click(function(){
        searchWrapHeader.fadeIn();
        searchOverlay.fadeIn();
    });
    searchOverlay.click(function(){
        searchOverlay.fadeOut();
        searchWrapHeader.fadeOut();
    });
});